(function () {
  'use strict';

  angular
    .module('events.view')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('events.view', {
        url: '/:eventId',
        templateUrl: 'events/view/view.tpl.html',
        controller: 'EventsViewCtrl',
        controllerAs: 'vm'
      });
  }
}());
